import React, { Component } from 'react';
import PortfolioCarousel from '../components/Carousel';

import full01 from '../assets/images/fulls/Roostr1.png'
import full02 from '../assets/images/fulls/Roostr2.png'
import full03 from '../assets/images/fulls/Roostr3.png'

import moody1 from '../assets/images/fulls/Moody1.png'
import moody2 from '../assets/images/fulls/Moody2.png'
import moody3 from '../assets/images/fulls/Moody3.png'
import moody4 from '../assets/images/fulls/Moody4.png'

import Twilio1 from '../assets/images/fulls/Twilio1.png'
import Twilio2 from '../assets/images/fulls/Twilio2.png'
import Twilio3 from '../assets/images/fulls/Twilio3.png'

import YoClock1 from '../assets/images/fulls/YoClock1.png'
import YoClock2 from '../assets/images/fulls/YoClock2.png'


class Portfolio extends Component {
    render(){
        return(
            <section id="two" className='portfolio'>
                <h2>Recent Work</h2>

                <div style={{marginTop: '3em'}}>
                    <PortfolioCarousel 
                    video={[{title: 'Roostr Demo', src: "https://www.youtube-nocookie.com/embed/gcPyv4YPoHM"}]} 
                    photo={[{img: full01, text: 'Roostr.tech Home Page'}, {img: full02, text: 'Roostr.tech Home Page'}, {img: full03, text: 'Roostr.tech Home Page'}]} 
                    name='Roostr.tech' 
                    description='A short term rental property management web application that allows owners to easily manage employees, properties and guest reservations in one place.'
                    repo='https://github.com/Lambda-School-Labs/labspt2-rental'
                    deploy='https://www.roostr.tech'
                    />

                    <PortfolioCarousel 
                        video={[{title: 'Twilio Zeit Integration Demo', src: "https://www.youtube-nocookie.com/embed/wFwUlqiwjyk"}]} 
                        photo={[{img: Twilio1, text: 'Twilio Zeit Integration'}, {img: Twilio2, text: 'Twilio Zeit Integration'}, {img: Twilio3, text: 'Twilio Zeit Integration'}]} 
                        name='Twilio Zeit Integration' 
                        description='A Twilio Integration that allows Zeit users to easily setup Twilio on projects, test Twilio services, and manage call and SMS logs.'
                        repo='https://github.com/juliejonak/twilio-zeit-integration'
                        deploy='https://zeit.co/integrations/twilio'
                    />

                    <PortfolioCarousel 
                        video={[{title: 'Moody.io Demo', src: "https://www.youtube-nocookie.com/embed/5nQOzJUozgs"}]} 
                        photo={[{img: moody1, text: 'Moody.io Home Page'}, {img: moody2, text: 'Moody.io Moody Tracker'}, {img: moody3, text: 'Moody.io Daily Questionaire'}, {img: moody4, text: 'Moody.io Activity Tracker'}]} 
                        name='Moody.io' 
                        description="Moody.io is a mood tracker app that correlates users' daily activities with their mood, using data analysis to track correlations over time, as well as running sentiment analysis to the users' Twitter account."
                        repo='https://github.com/juliejonak/Moody'
                        deploy='https://brave-boyd-11d447.netlify.com'
                    />

                    <PortfolioCarousel 
                        video={[]}
                        photo={[{img: YoClock1, text: 'Yo Clock Home'}, {img: YoClock2, text: 'Yo Clock Notification'}]} 
                        name='Yo Clock!' 
                        description="Yo-Clock is a Google Chrome extension that helps users stay focused and productive. Users can set a customized timer to remind them of their next task to do, or if they are on a single page for too long, Yo Clock will remind them to set a timer to avoid the rabbit hole of the internet."
                        repo='https://github.com/juliejonak/Yo-Clock'
                        deploy='https://chrome.google.com/webstore/detail/yo-clock/pnepmipofgeonhcliddmmmgccfeigonc?hl=en-US'
                    />

                </div>

                {/* <ul className="actions">
                    <li><a href="#" className="button">Full Portfolio</a></li>
                </ul> */}
            </section>
        )
    }
}

export default Portfolio;