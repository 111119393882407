import React from 'react'

class Contact extends React.Component {
    render() {
        return (
            <section id="three">
                <h2>Get In Touch</h2>
                <p>Questions? Comments? Just want to say hello? Please reach out below!</p>
                
                <div className="row">
                    <div className="8u 12u$(small)">
                        <form action="https://formspree.io/juliejonak10@gmail.com" method="POST">
                            <div className="row uniform 50%">
                                <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                <div className="6u 12u$(xsmall)"><input type="email" name="_replyto" id="email" placeholder="Email" /></div>
                                <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                            </div>

                            <ul className="actions" style={{marginTop: '1em'}}>
                                <li><input type="submit" value="Send Message" target="_blank" /></li>
                            </ul>
                        </form>
                    </div>
                    <div className="4u 12u$(small)">
                        <ul className="labeled-icons" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                            <li>
                                <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                <a href="tel:8328595441">832-859-5441</a>
                            </li>
                            <li>
                                <h3 className="icon fa-twitter"><span className="label">Twitter</span></h3>
                                <a href="https://twitter.com/juliejonak">@juliejonak</a>
                            </li>                                    
                            <li>
                                <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                <a href="mailto:juliejonak10@gmail.com?Subject=Can we schedule a meeting?&body=You sound like a great candidate for our open developer position.">juliejonak@gmail.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contact;
