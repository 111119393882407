import PropTypes from 'prop-types';
import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// Iterate and improve: on click, component expands and opens Lightbox style, to show more full screen views of the photos and video. For now, small thumbnails will suffice.

// To get to the right size, had to adjust in 'react-responsive-carousel/lib/styles/carousel.min.css' this line: .carousel{position:relative;width:370px !important} to contain !important
// Not ideal - in future, refactor to override just this instance of Carousel

class PortfolioCarousel extends Component {
    constructor(){
        super();
        this.state = {
            isOpen: false,
            width: 370,
        }
    }

    openLightbox = (e) => {
        // set to 740 open
        // 370 closed
        e.preventDefault();
        this.setState({
            isOpen: !this.state.isOpen
        })
        console.log('triggered: ', this.state.isOpen)
    }

    render(props){
        const {video, photo, name, description, repo, deploy} = this.props;
        let videoDiv = '';
        let photoDiv = '';
        let deployButton = '';

        if(video.length !== 0){
            for(let i=0; i<video.length; i++){
                videoDiv = video.map(v => {
                    return <div>
                        <iframe
                            title={v.title}
                            width='500'
                            height='300'
                            src={v.src}
                            frameborder='0'
                            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                            allowfullscreen='allowfullscreen'
                            style={{marginTop: '50px'}}
                        ></iframe>
                    </div>
                })
            }
        }

        if(photo.length !== 0){
            for(let i=0; i<photo.length; i++){
                photoDiv = photo.map(p => {
                    return <div>
                        <img src={p.img} alt={p.text}/>
                    </div>
                })
            }
        }

        if(deploy){
            deployButton = <a className='button'  target="_blank" rel="noopener noreferrer" href={deploy} style={{marginLeft: '1em'}}>View Deploy</a>
        }



        return(
        <div className="6u 12u$(xsmall) work-item carouselComponent">

            <Carousel showThumbs={false} infiniteLoop={true} swipeable={true} useKeyboardArrows={true} >
                    {photoDiv}
                    {videoDiv}
            </Carousel>

            <h3>{name}</h3>
            <p>{description}</p>
            <a className='button'  target="_blank" rel="noopener noreferrer" href={repo} style={{marginLeft: '2%'}}>View Code</a>
            {deployButton}
        </div>
        )}
}

Carousel.propTypes = {
    video: PropTypes.array,
    photo: PropTypes.array,
    name: PropTypes.string,
    description: PropTypes.string,
    repo: PropTypes.string,
    deploy: PropTypes.string
};

export default PortfolioCarousel;