import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import Contact from '../components/Contact';
import Portfolio from '../components/Portfolio';

import resume from '../assets/images/JulieJonakResume.pdf';
import headshot from '../assets/images/Julie2.jpg';

class HomeIndex extends React.Component {
    render() {
        const siteTitle = "Julie Jonak";
        const siteDescription = "Full stack developer and T-Rex lover";

        return (
            <Layout>
                <Helmet>
                    <title>{siteTitle}</title>
                    <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">
                    <section id="one">
                        
                        <header className="major">
                            <h2>Welcome.</h2>
                        </header>
                        <div style={{display: 'flex'}}>
                            <img src={headshot} style={{borderRadius: '50%', width: '100px', height: '100px', marginRight: '2em'}} />
                            <p style={{marginTop: '0.5em'}}>My name is Julie Jonak. I am a full stack web developer with a background in small business, looking for new projects that solve challenging problems and emphasize user focused design.</p>
                        </div>
                        <p>With nine years' experience running an online business, my strengths lie in collaborating on teams and producing a high quality product. A lifelong love of travel has inspired me to seek ways in which code can create opportunities for people around the world.</p>
                        <p>As a Director of the Houston Women Who Code chapter, I've had the opportunity to share development opportunities with women and children in our community. When not working or volunteering, I enjoy building Alexa apps and hunting down taco trucks.</p>
                        {/* <ul className="actions">
                            <li><a href={resume} download className="button">Download Resume</a></li>
                        </ul> */}
                    </section>
                    <Portfolio />
                    <Contact />
                </div>
            </Layout>
        )
    }
}

export default HomeIndex